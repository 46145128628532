.get-touch {
    background-color: $secondary-bg;
    display: flex;
    justify-content: center;
    margin-bottom: 150px;

    @media (max-width: 767px) {
        margin-bottom: 75px;
    }

    &__form {
        max-width: 590px;
        padding: 100px 0;

        @media (max-width: 767px) {
            padding: 75px 0;
        }

        @media (max-width: 589px) {
            max-width: calc(100% - 50px);
            padding: 75px 0;
        }

        h1 {
            margin-bottom: 50px;
            text-align: center;
            background: url(../images/bg-text.jpg) no-repeat center;

            background-clip: text;
            -webkit-background-clip: text;
            color: rgba(0,0,0,.2);
            animation: anim 7s infinite ease-in;
        }

        .btn {
            background: url(../images/bg-text.jpg) no-repeat center;
            color: #fff;
            width: 170px;
            text-align: center;
            height: 48px;
            float: right;

            &:hover {
                animation: animbtn 7s infinite ease-in;
            }
        }
    }

    .thanks-message {
        text-align: center;
        padding: 200px 0;
        display: none;

        @media (max-width: 767px) {
            padding: 75px 25px;
        }

        h1 {
            margin-bottom: 25px;
            background: url(../images/bg-text.jpg) no-repeat center;

            background-clip: text;
            -webkit-background-clip: text;
            color: rgba(0,0,0,.2);
            animation: anim 7s infinite ease-in;
        }

        p {
            line-height: $global-lineheight;
            max-width: 55%;
            text-align: center;
            margin: 0 auto;

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
}
