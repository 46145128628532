html {
    box-sizing: border-box;
    font-size: $base-font-size;
}

body {
    background: $primary-bg;
    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    line-height: $secondary-lineheight;
    color: $body-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin: 0;
    
}

.wrapper {
    overflow-x: hidden;
    position: relative;
    display: none;
}

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

.container {
    width: $global-width;
    margin: 0 auto;

    @media (max-width: 1169px) {  
        width: 100%;
    }
}

@-webkit-keyframes anim { 
    0% {
        background-position: top;
    }
    50% { 
        background-position: bottom;
    }
    100% { 
        background-position: top;
    }
}
@-moz-keyframes anim { 
    0% {
        background-position: top;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: top;
    }
}
@-ms-keyframes anim { 
    0% {
        background-position: top;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: top;
    }
}
@keyframes anim { 
    0% {
        background-position: top;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: top;
    }
}

@-webkit-keyframes animbtn { 
    0% {
        background-position: center;
    }
    50% { 
        background-position: bottom;
    }
    100% { 
        background-position: center;
    }
}
@-moz-keyframes animbtn { 
    0% {
        background-position: center;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: center;
    }
}
@-ms-keyframes animbtn { 
    0% {
        background-position: center;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: center;
    }
}
@keyframes animbtn { 
    0% {
        background-position: center;
    }
    50% {
        background-position: bottom;
    }
    100% {
        background-position: center;
    }
}
