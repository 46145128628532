.stats {
    display: flex;
    font-family: $secondary-font-family;
    margin-bottom: 150px;

    @media (max-width: 767px) {  
        flex-direction: column;
        margin-bottom: 75px;
    }

    &__textbg {
        background: url(../images/bg-text.jpg) no-repeat center;

        background-clip: text;
        -webkit-background-clip: text;
        color: rgba(0,0,0,.2);
        animation: anim 7s infinite ease-in;
    }

    &__single {
        padding: 0 36px;
        border-right: 2px solid $divider-color;
        flex: 0 0 33%;
        text-align: center;

        @media (max-width: 767px) {  
            flex: 0 0 100%;
            padding: 0 65px;
            margin-bottom: 25px;
            border-right: 0;
            box-sizing: border-box;
        }

        &:last-of-type {
            border-right: 0;
        }

        span {
            display: block;
            font-weight: 700;
        }

        &-upto {
            font-size: 24px;
            line-height: 140%;
            font-family: $body-font-family;

            @media (max-width: 991px) {  
                font-size: 20px;
            }
        
            @media (max-width: 767px) {  
                font-size: 16px;
            }
        }

        &-percent {
            font-size: 80px;
            line-height: 100%;
            margin-bottom: 15px;
            font-variant-numeric: lining-nums;
        }

        &-txt {
            font-size: 30px;
            line-height: 100%;

            @media (max-width: 991px) {  
                font-size: 24px;
            }
        }
    }

    
}

.slider {
    display: none;
}

@media (max-width: 767px) {  
    @keyframes slidein {
        from { transform: scaleX(0); }
        to   { transform: scaleX(1); }
    }
    
    .slider {
        width: 80px;
        height: 10px;
        background-color: $divider-color;
        margin: 25px auto 0;
        display: block;
        
        position: relative;
    
        .slider-animate {
            animation: 2s slidein;
            transform-origin: left center;
            background: url(../images/bg-text.jpg) no-repeat center;
            width: 80px;
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }
}

