$primary-bg: #101010;
$secondary-bg: #222222;

$body-font-color: #fff;
$secondary-text: rgba(#fff, 0.5);
$divider-color: rgba(#fff, 0.25);
$light-color: rgba(#000, 0.15);

$green: #53BB53;


