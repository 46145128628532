@import url('https://fonts.googleapis.com/css2?family=Syne:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@400;700&display=swap');

$body-background: #101010 !default;
$body-font-family: 'Epilogue', sans-serif !default;
$global-weight-normal: 400 !default;
$global-lineheight: 140% !default;
$body-font-color: #fff !default;
$base-font-size: 100% !default;
$global-width: 1170px;

$secondary-font-family: 'Syne', sans-serif;