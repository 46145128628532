footer {
    padding-bottom: 50px;

    @media (max-width: 1169px) {  
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {  
        padding-bottom: 25px;
    }
    
    p {
        float: left;
        font-size: 14px;
        color: $secondary-text;

        @media (max-width: 767px) {  
            float: none;
            text-align: center;
            font-size: 12px;
        }
    }

    img {
        float: right;

        @media (max-width: 767px) {  
            float: none;
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }
}