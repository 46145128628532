button, input, optgroup, select, textarea {
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
select {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 8px;
    height: 40px;
    border: 1px solid $light-color;
    transition: border-color 0.35s;
}
button, input[type=submit] {
    padding: 0;
    appearance: none;
    border: 0;
    background: transparent;
    line-height: 1;
    cursor: pointer;
    // background-color: $primary-colour;
    color: #fff;
    padding: 13px 15px;
    font-weight: 700;
    // &:focus, &:hover {
    //     background-color: $primary-colour-dim;
    // }
    box-sizing: border-box;
    font-family: $body-font-family;
    font-size: 16px;
    font-weight: 700;
}
fieldset {
    border: 2px solid $light-color;
    legend {
        padding: 0 10px;
        font-weight: bold;
    }
}
label {
    margin-bottom: 5px;
    display: block;
}
.form-field {
    margin-bottom: 25px;
}
input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], textarea {
    width: 100%;
    padding: 13px 15px;
    height: 48px;
    border: 1px solid  $light-color;
    transition: border-color 0.35s;
    box-sizing: border-box;
    margin-bottom: 5px;
    font-family: $body-font-family;
    font-size: 16px;

    &:hover {
        border: 1px solid $green;
    }

    &:focus {
        border: 2px solid $green;
    }
}
textarea {
    height: auto;
    min-height: 160px;
}
.error {
    color: #ff0057;
}
