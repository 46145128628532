$header-font-family: $body-font-family !default;
$header-font-weight: $global-weight-normal !default;
$secondary-lineheight: 100% !default;
$header-margin-bottom: 0.5rem !default;
$small-font-size: 80% !default;

$paragraph-margin-bottom: 1rem !default;

$header-styles: (
    small: (
        'h1': ('font-size': 48),
        'h2': ('font-size': 30),
        'h3': ('font-size': 16),
        'h5': ('font-size': 14),
        'h6': ('font-size': 12),
    ),
    medium: (
        'h1': ('font-size': 64),
        'h2': ('font-size': 40),
        'h3': ('font-size': 20),
        'h5': ('font-size': 16),
        'h6': ('font-size': 14),
    ),
    large: (
        'h1': ('font-size': 80),
        'h2': ('font-size': 50),
        'h3': ('font-size': 24),
        'h5': ('font-size': 16),
        'h6': ('font-size': 14),
    ),
) !default;

h1 {
    font-size: 80px;
    line-height: 100%;
    font-family: $secondary-font-family;
    font-weight: 700;

    @media (max-width: 991px) {  
        font-size: 64px;
    }

    @media (max-width: 767px) {  
        font-size: 48px;
    }
}

h2 {
    font-size: 50px;
    line-height: 100%;
    font-family: $secondary-font-family;
    font-weight: 700;

    @media (max-width: 991px) {  
        font-size: 40px;
    }

    @media (max-width: 767px) {  
        font-size: 30px;
    }
}

h3 {
    font-size: 24px;
    line-height: 140%;
    font-family: $body-font-family;
    font-weight: 700;

    @media (max-width: 991px) {  
        font-size: 20px;
    }

    @media (max-width: 767px) {  
        font-size: 16px;
    }
}

h5 {
    font-size: 16px;
    line-height: 150%;
    font-family: $body-font-family;
    font-weight: 700;

    @media (max-width: 991px) {  
        font-size: 16px;
    }

    @media (max-width: 767px) {  
        font-size: 14px;
    }
}

h6 {
    font-size: 14px;
    line-height: 130%;
    font-family: $body-font-family;
    font-weight: 400;

    @media (max-width: 991px) {  
        font-size: 14px;
    }

    @media (max-width: 767px) {  
        font-size: 12x;
    }
}

p {
    font-size: 24px;
    line-height: 140%;
    font-family: $body-font-family;
    font-weight: 400;

    @media (max-width: 991px) {  
        font-size: 20px;
    }

    @media (max-width: 767px) {  
        font-size: 16px;
    }
}
