.features-block {
    border-bottom: 2px solid $divider-color;
    padding-bottom: 50px;
    margin-bottom: 150px;

    @media (max-width: 767px) {  
        padding-bottom: 20px;
        margin-bottom: 100px;
    }
    
    h2 {
        margin-bottom: 75px;
        text-align: center;
    }

    &-inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__single {
        flex: 0 0 50%;
        text-align: center;
        padding: 0 25px;
        box-sizing: border-box;
        margin-bottom: 100px;

        @media (max-width: 767px) {  
            flex: 0 0 100%;
            margin-bottom: 54px;
        }

        h3 {
            margin-bottom: 15px;

            @media (max-width: 767px) {  
                margin-bottom: 5px;
            }
        }
        
        p {
            line-height: $global-lineheight;
            margin-bottom: 0;
        }

        img {
            margin-bottom: 40px;

            @media (max-width: 767px) {  
                height: 100px;
                margin-bottom: 30px;
            }
        }
    }
}