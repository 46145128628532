header {
    font-size: 24px;
    position: relative;

    @media (max-width: 991px) {
        font-size: 20px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }

    .header-img {
        position: absolute;
        top: 10px;
        right: -20px;
        left: 0;
        width: 100%;
        max-width: 1900px;
        height: 100%;

        @media (max-width: 767px) {
            top: 50px;
        }

        video {
            animation: video 1.5s linear forwards;
            object-fit: cover;
            display: block;
            position: absolute;
            width: auto;
            height: 100%;
            right: -470px;
            top: 50px;

            @media (max-width: 768px) {
                height: 80%;
            }

            @media (max-width: 767px) {
                animation: video-mobile 1.5s linear forwards;
            }            

            @media (max-width: 480px) {
                height: 60%;
                right: -340px;
            }
        }
    }

    .container {

        @media (max-width: 1169px) {
            padding-left: 25px;
            padding-right: 25px;
            box-sizing: border-box;
        }
    }

    .header__txt {
        position: relative;
        z-index: 1;
    }

    .logo {
        text-decoration: none;
        font-family: $secondary-font-family;
        border-bottom: 0;
        font-size: 30px;
        margin-top: 125px;
        display: inline-block;

        @media (max-width: 767px) {
            font-size: 20px;
            margin-top: 50px;
        }
    }

    h1 {
        margin-top: 315px;
        max-width: 950px;
        font-family: $secondary-font-family;
        line-height: $secondary-lineheight;
        margin-bottom: 50px;
        background: url(../images/bg-text.jpg) no-repeat;
        background-clip: text;
        -webkit-background-clip: text;
        color: rgba(0,0,0,.2);
        animation: anim 7s infinite ease-in;

        @media (max-width: 949px) {
            max-width: calc(100% - 50px);
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    p {
        margin-bottom: 50px;
        max-width: 560px;
        line-height: $global-lineheight;

        @media (max-width: 569px) {
            max-width: 99%;
        }
    }

    ul {
        list-style: none;
        max-width: 670px;
        line-height: $global-lineheight;
        margin-left: 0;
        margin-bottom: 150px;

        @media (max-width: 767px) {
            margin-bottom: 75px;
        }

        @media (max-width: 669px) {
            max-width: 100%;
        }

        li {
            border-bottom: 2px solid $divider-color;
            margin-bottom: 25px;
            padding-bottom: 25px;
            padding-right: 60px;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            @media (max-width: 767px) {
                padding-right: 0;
            }
        }
    }
}

@keyframes video {
  0%  { opacity: 0; margin-top: 70vh; transform: scale(1.25); }
  40% { opacity:1; margin-top: 15vh;  transform: scale(1.25); }
  75% { opacity:1; margin-top: 15vh;  transform: scale(1.25); }
  100% { margin-top: 0; transform: scale(1); transform: rotate(15deg); }
}

@keyframes video-mobile {
    0%  { opacity: 1; margin-top: 30vh; left: 0; transform: scale(1.25); }
    40% { opacity:1; margin-top: 30vh; left: -300px;  transform: scale(1.25); }
    60% { opacity:1; margin-top: 30vh; left: -300px;  transform: scale(1.25); }
    100% { margin-top: 0; left: -360px; transform: scale(0.75); transform: rotate(15deg); }
  }
