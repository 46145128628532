#gradient-canvas {
    width:100%;
    height:540px;
    --gradient-color-1: #ef008f; 
    --gradient-color-2: #6ec3f4; 
    --gradient-color-3: #7038ff;  
    --gradient-color-4: #ffba27;
}

.testimonial-block {
    position: relative;
    height: 540px;
    margin-bottom: 150px;

    @media (max-width: 767px) {  
        margin-bottom: 75px;
    }

    &-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            font-family: $secondary-font-family;
            line-height: $secondary-lineheight;
            text-align: center;
            max-width: 970px;
            margin-bottom: 50px;
            padding: 0 25px;
        }

        .logos-imgs {

            img {
                display: inline-block;
                margin: 0 55px;

                @media (max-width: 991px) {  
                    margin: 0 20px;
                }
            }
        }
    }
}